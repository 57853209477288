table.table-comparison {
  @extend .table;

  table-layout: fixed;
  margin-bottom: 20px;

  th {
    position: sticky;
    z-index: 1;
    top: 0px;
    background-color: $light;
    color: $primary;

    &:first-of-type {
      width:35%;
    }
  }
}