.list-icons li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.list-icons li:last-child {
  margin-bottom: 0;
}

.list-icons img,
.list-icons svg {
  flex: 0 0 auto;
  margin-right: 15px;

  @include media-breakpoint-up(lg) {
    margin-right: 20px;
  }
}
