.filter-heading {
  white-space: nowrap !important;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }

  &:after {
    font-family: "Font Awesome 6 Free";
    content: "\f00d";
    float: right;
    margin-left: 0.2em;
  }

  &.collapsed:after {
    content: "\f067";
  }
}

.filter {
  background-color: transparent;

  h4,
  h5 {
    margin-bottom: 0.75em;
    margin-top: 0.75em;
  }

  h4 {
    font-size: 16px;
  }

  &.list-group-item {
    padding-left: 0;
  }
}

.filtergroup {
  font-size: 1rem;
}
