.star-rating {
    display: flex;
    [data-starvalue] {
        cursor: pointer;
    }
    .zero-area {
        width: 0.5em;
        user-select: none;
    }
    ul, li {
        padding-left: 0 !important;
        display: flex !important;
        color: gold;
        list-style-type: none !important;
        list-style-image: none !important;
    }

}

.star-rating_star {
    &::before {
        content: '\f005';
    }

    font-family: "Font Awesome 6 Free";
    font-weight: 400;
    &.on {
        font-weight: 900;
    }    
}