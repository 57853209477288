@import './fonts';
@import './fontawesome';
@import './functions/shade';
@import './functions/tint';
@import './variables';
@import './bootstrap';
@import './typography';

// Overwrites for Bootstrap components
@import './components/breadcrumb';
@import './components/buttons';
@import './components/card';
@import './components/container';
@import './components/nav';
@import './components/navbar';
@import './components/checkboxes';
@import './components/list-groups';

// Custom components
@import './components/hero';
@import './components/list-icons';
@import './components/scoring-category';
@import './components/tab-bar';
@import './components/toggle';
@import './components/user-nav';
@import './components/material-switch';
@import './components/spinner';
@import './components/filters';
@import './components/pagination';
@import './components/return-to-top';
@import './components/firacomment';
@import './components/matrix';
@import './components/topicnav';
@import './components/cms';
@import './components/back-to-top';
@import './components/leftmenu';
@import './components/comparison';
@import './components/messages';
@import './components/questionIcons';
@import './components/forms';
@import './components/multiselect';
@import './components/bubbles';
@import './components/stars';

// Pages
@import './pages/issue-card';
@import './pages/report';
@import './pages/buyerhome';
@import './pages/search';
@import './pages/loginpage';
@import './pages/dashboard';
@import './pages/registration';
@import './pages/community';

// General styles
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Needed for Bootstrap’s scrollspy (https://getbootstrap.com/docs/4.1/components/scrollspy/)
  position: relative;
}

hr {
  margin-top: 3em;
  margin-bottom: 3em;
}

img {
  max-width: 100%;
}

a:focus {
  // Remove outline on focus, be sure to set focus styles instead
  outline: 0;
}
a[disabled] {
    pointer-events:none;
}

.keep-together {
    //Warning: works only on block-level elements
    break-inside: avoid !important;
}

