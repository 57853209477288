.navbar {
  padding: 5px 0;
  font-size: 13px;
}

.navbar-brand {
  @include media-breakpoint-down(xs) {
    width: 60px;
  }
}

.navbar-brand img {
    height: 40px;
}

.nav-item.active {
  @extend .shadow, .mx-1;
}
