.issue-card__scoring {
  @extend .keep-together;

  margin-top: 40px;
  padding: 30px;
  border-radius: 8px;
  background: inherit;
  color: $primary;
  font-size: 14px;
  border: 1px solid $primary;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
}

.issue-card__scoring-legend {
  display: flex;
  flex-direction: column;
  margin-top: 0;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  @media print {
    flex-direction: row;
  }
}


.issue-card__scoring-legend_graphic {
  align-self: center;

  @include media-breakpoint-up(md) {
    padding-right: 40px;
    align-self: start;
  }

  @media print {
    padding-right: 40px;
    align-self: start;
  }

  img {
    @extend .mb-2;
    max-height: 225px;
  }


}




