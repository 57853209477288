// Variables for Bootstrap

/*
 * Grid
 */
$grid-gutter-width: 50px;

/*
 * Colors
 */
$primary: #0047a4;
$secondary: #ff7700;
$tertiary: #454b97;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;


$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'info': $gray-400
);

/*
 * Typography
 */
$font-family-base: Noto Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

$font-size-base: 1rem;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.2;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base * 0.96;

$headings-font-weight: bold;

$lead-font-size: (24rem / 16);

$link-decoration: underline;
$link-hover-color: $primary;
$link-hover-decoration: none;

/*
 * Sizing
 */
$spacer: 10px;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 4,
  4: $spacer * 8,
  5: $spacer * 12,
);

$sizes: (
  15: 15%,
  20: 20%,
  33: 33%,
  40: 40%,
  60: 60%
);

/*
 * Components
 */

// Breadcrumb
$breadcrumb-padding-x: 0;
$breadcrumb-divider: quote('›');

// Button

// Card
$card-border-radius: 8px;

// Jumbotron
$jumbotron-padding: 0;
$jumbotron-bg: transparent;

// Nav
$nav-tabs-border-radius: 0;
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 30px;

// Navbar
$navbar-padding-x: 0;
$navbar-brand-padding-y: 0.25rem;

// Spinner
$spinner-height: 2rem;
$spinner-width: 2rem;
$spinner-border-width: .25em;
$spinner-height-sm: 1rem;
$spinner-width-sm: 1rem;
$spinner-border-width-sm: .2em;
