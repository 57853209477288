a#scrolltotop {
  position: fixed;
  right: 50%;
  bottom: 10px;
  z-index: 1040;
  cursor: pointer;
  display: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  text-decoration: none;

  color: $white;
  background-color: $primary;

  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  &:before {
    content: '\f102';
  }

  @media print {
    display: none !important;
  }
}


