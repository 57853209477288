.card {
  padding: 30px;
  border: 0;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);

  @include media-breakpoint-up(lg) {
    padding: 60px;
  }
}

.card-title {
  margin-bottom: 40px;
}

.card-text {
  font-size: 16px;
}
