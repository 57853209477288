@import url("https://fonts.googleapis.com/css?family=Kaushan+Script");
html,
body {
  height: 100%;
}
.carbonated {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  background: radial-gradient(circle at top left, $light, #1169d8 25%);

  background-size: 200% 200%;
  animation: bannerbg ease 8s infinite;
  div {
    h2 {
      font-weight: bold;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      font-family: "Kaushan Script", cursive;
      font-size: 3.5rem;
    }
  }

  @keyframes bannerbg {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: white;
  }

  li {
    position: absolute;
    list-style: none;
    display: block;
    opacity: 0.2;
    font-size: 4rem;
    animation: bubble-up linear 10s infinite, bubble-wobble 7s ease infinite;

  }

  @for $i from 1 through 20 {
    @each $n in $i ($i + 4) ($i + 8){
      li:nth-child(#{$n}) {
        left: ($n / 1.2 * 10%);
        @if $i == 1 {
          font-size: (1 / $n * 15px);
          opacity: 0.1;
          animation-delay: 0s;
          animation-duration: 5s;
        }
        @if $i == 2 {
          font-size: 20px;
          opacity: 0.05;
          animation-delay: 4s;
          animation-duration: 10s;
        }
        @if $i == 3 {
          font-size: (1 / $n * 20px);
          opacity: 0.08;
          animation-delay: 2s;
          animation-duration: 3s;
        }
        @if $i == 4 {
          font-size: (5px * $n);
          opacity: 0.05;
          animation-delay: 5s;
          animation-duration: 8s;
        }
      }
    }
  }
}

@keyframes bubble-up {
  0% {
    transform: rotate(0deg) scale(1);
    bottom: 0;
  }
  50% {
    transform: rotate(180deg) scale(2);
  }

  100% {
    transform: rotate(360deg) scale(1);
    bottom: 100%;
  }
}

@keyframes bubble-wobble {
    0% {
        transform: translateX(0);
    }
  25% {
    transform: translateX(-100px);
  }
  75% {
    transform: translateX(150px);
  }
    100% {
        transform: translateX(0);
    }

}
