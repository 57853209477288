.home__container {
  @extend .container-fluid, .container-centered;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  background: url('../images/hero-pattern.svg'), linear-gradient($primary 60%, white 40%);
}

.home__container .jumbotron {
  @extend .text-center, .mb-3, .pt-3;
  color: white !important;

  h1 {
    @extend .display-4, .mb-2;
    font-weight: bold;
  }
}

.home__deck {
  @extend .card-deck, .container, .justify-content-around;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
     flex-direction: row;
  }
}

.home__deck .card {
  @extend .text-center;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: 
    "logo desc"
    "logo action";

  align-items: start;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 3.5fr 1fr;
    grid-template-areas: 
    "logo"
    "desc"
    "action";
    padding-left: 30px;
    padding-right: 30px;
  }
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  min-height: 14rem;
}

.home__deck .card-img-top {
  @extend .img-fluid, .mx-auto, .my-2;

  grid-area: logo;

  display: block;
  padding: 10px;
  padding-top: 0;
//   height: fit-content;
  width: auto;
  pointer-events: none;
      // filter: brightness(0) saturate(100%) invert(14%) sepia(74%) saturate(4888%) hue-rotate(207deg) brightness(88%) contrast(102%)

  @include media-breakpoint-up(lg) {
    height: 5rem;
    padding: 0;
  }
}

.home__deck .card-body {
  grid-area: desc;
}

.home__deck .card-title {
  // text-align: center;
  margin-bottom: .75rem;
  font-size: 1.25rem;
}

.home__deck .card-subtitle {
  @extend .text-center, .small;
  font-size: 1.0rem;
  font-weight: 400;
  position: absolute;
  background: $white;
  color: $primary;
  transform: rotate(45deg);
  top: 50px;
  right: -50px;
  width: 250px;
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
}

.home__deck .card-footer {
  grid-area: action;
  border: 0;
  background-color: transparent;
}

.home__deck .card-footer .btn {
  @extend .text-white;
}

.home__deck .card-footer a {
  text-decoration: none;
}

.home__deck .inactive {

  .card-img-top,
  .card-title, 
  .card-body {
    opacity: 0.3;
  }
}

.footer {
  width: 100%;
  bottom: 0;
  color: $primary;
  background-color: $light;
  padding-bottom: 20px;
  padding-top: 20px;

  a {
    color: $primary;
  }
}


.footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-stats {
  display: flex;
  justify-content: space-around;
  text-align: center;
  /*  border-radius: .5rem;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  */
  padding-top: 20px;
}

.footer-stats h2 {
  margin-bottom: .5rem;
}

.hero-buyerhome {
  background: url('../images/hero-pattern.svg'),
    linear-gradient(to left, $tertiary, $primary);
}