/*
 * Categories
 */
.report__categories {
  margin-bottom: 100px;
  padding: 30px;
  @media print {
    padding: 0 !important;
  }

  article {
    break-inside: avoid;
  }

  section {
    @extend .clearfix;
  }

  @include media-breakpoint-down(sm) {
    margin-right: -25px;
    margin-left: -25px;
  }
  @include media-breakpoint-up(lg) {
    /*    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    */
    margin-right: 60px;
    padding: 80px;
  }
}

@mixin headerbackground($bg-url) {
  @include media-breakpoint-up(lg) {
    background-repeat: no-repeat;
    background-size: 100% 150em, contain;
    background-image: linear-gradient(to bottom, transparent, $light 25%), url($bg-url);
  }
}

.header-organization {
  @include headerbackground("../images/bg_organization.jpg");
}

.header-impactanalysis {
  @include headerbackground("../images/bg_impactanalysis.jpg");
}

.header-stakeholders {
  @include headerbackground("../images/bg_stakeholders.jpg");
}

.header-materialitymatrix {
  @include headerbackground("../images/bg_materialitymatrix.jpg");
}

.header-managementapproach {
  @include headerbackground("../images/bg_managementapproach.jpg");
}

.header-policies {
  @include headerbackground("../images/bg_policies.jpg");
}

.header-certificates {
  @include headerbackground("../images/bg_certificates.jpg");
}

.header-governance {
  @include headerbackground("../images/bg_governance.jpg");
}

.header-kpimanagement {
  @include headerbackground("../images/bg_kpimanagement.jpg");
}
/*
 * Categories navigation
 */
.report__categories-nav {
  margin-bottom: 40px;
  @include media-breakpoint-up(lg) {
    /*
    flex: 0 0 300px;
*/
    margin-right: -140px;
    margin-left: 60px;
    /*    margin-bottom: 0; */
    float: right;
    display: inline-block;
    width: 40%;

    li.nav-item {
      margin-right: 80px;
    }
  }
}

.report__categories-article:not(.active) {
  margin-bottom: 100px;
}
/*
 * Pullout image from text
 * Achieved by moving both x-margins to the right.
 * Note that left and right are using different percentages, so it will also blow up the element a bit
 */
.report__pullout-img {
  margin-bottom: $line-height-base * 2em;
  @include media-breakpoint-up(lg) {
    margin-right: calc(-30% - (#{$line-height-base} * 2em));
    margin-left: calc(10% + (#{$line-height-base} * 2em));
  }
}
/*
 * Footer
 */
.report__footer {
  padding: 25px;
  @include media-breakpoint-down(xs) {
    margin-left: -25px;
    margin-right: -25px;
  }
  @include media-breakpoint-up(md) {
    padding: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding: 60px;
  }
}

.report__categories-section {

  clear: right;
  padding-bottom: 40px;

  dl {
    margin-bottom: 1.5em;
  }

  dt {
    float: left;
    clear: both;

    &:not(.labelOnly):after {
      content: ':\00a0';
    }
  }

  h3 {
    margin-bottom: 0.5em;
  }

  table {
    break-before: avoid;
  }

  p {
    margin-bottom: 1em;
  }

  ul.downloadlist {
    margin-bottom: 1.5em;
  }
  @media screen {
      &:first-of-type {
        margin-top: 24em;
      }
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;

  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
}

.valuechain {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1.5em;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: baseline;
  }

  h4 {
    color: $primary;
    background-color: $white;
    padding: 10px 20px;
    border: 1px solid $primary;
    margin-bottom: 0;
    @extend .ellipsis;
  }

  .list-group {
    position: relative;
    right: -10px;
  }

  .list-group-item {
    color: $light;
    background-color: $primary;
    border-radius: 0;
  }

  .level {
    width: 300px;
  }

  .arrow {
    right: 10px;
    padding-left: 20px;
    padding-right: 20px;

    &:before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f063";
      @include media-breakpoint-up(md) {
        content: "\f061";
      }
    }
  }
}

.filelink {
  &:after {
    @extend .ml-1;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f019";

  }
}
