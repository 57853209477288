.label {
  &:after {
    content: ':\00a0';
  }
}

.timForm {
    fieldset {
        @extend .border,.border-info, .mt-1, .p-1;
    }

    .row {
        @extend .form-group;

        > label {
            @extend .label, .col-form-label
        }

        .label {
            @extend .col-sm-4;
        }

        > div, .value {
            @extend .col-sm-7;
        }

        input:not([type="checkbox"]), textarea, select {
            @extend .form-control;
        }

        .questionIconContainer {
            @extend .col-sm-1;
        }
    }
}
