

//<-- main -->
.login-box { //box
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: $light;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  border-radius: 5px;

  padding: 40px 20px 0 20px;

  min-width: 70%;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    min-width: 35%;
    max-width: 60%;
  }

  .form-control {
    font-size: 1.25rem;
  }

  .input-group {
    input {
      padding-left: 2em;
      margin-bottom: 1em;
    }

    &:before {
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      position: absolute;
      font-size: 1.25em;
      color: $primary;
      left: 0.5em;
      top: 0.25em;
      width: 1em;
      z-index: 10;
    }

    &.company:before {
      content: "\f0b1" ;
    }

    &.username:before {
      content: "\f007";
    }

    &.password:before {
      content: "\f023";
    }

  }
}

//<-----------------Release Notes and Forget Password------------------>
.forgot {
  font-size: 0.75rem;
  padding: 10px 0 20px 0;
}

.release-notes {
  text-align: right;
  font-size: 0.75rem;
}






