.breadcrumb {
  margin-bottom: 0;
  background: transparent;
  font-size: 11px;

  @include media-breakpoint-up(md) {
    font-size: 15px;
  }
}

.breadcrumb a {
  color: inherit;
}
