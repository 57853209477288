#columnLeft {
  height: 100%;
  /* Needed for when div is empty, otherwise columnMain will stay at left side. */
}

.topicSubNav .group {
  margin-bottom: 10px;
}

.topicSubNav ul.menu {
  margin: 0;
}

.topicSubNav ul.menu li {
  padding-left: 0;
  margin-left: 20px;
}

.topicSubNav h4 {
  border-bottom: 1px solid #D7CCC8;
  color: $primary;
  margin-left: 10px;
  margin-bottom: 0;
}

ul.menu,
ul.menu ul {
  list-style: none;
  list-style-image: none;
  padding: 0;
}

ul.menu {
  margin-bottom: 22px;
}

ul.menu li {
  border-bottom: 1px solid #D7CCC8;
}

ul.menu li a,
ul.menu li span {
  display: block;
  padding: 3px 0;
  color: #665953;
}

ul.menu li a {
  padding: 3px 0 3px 10px;
  text-decoration: none;
}

ul.menu li a:hover {
  text-decoration: none;
  background-color: #EFE4E1;
}

ul.menu li.active a {
  background-color: $light;
}

ul.menu ul {
  margin-bottom: 0;
}

ul.menu ul li a {
  padding: 1px 0 1px 5px;
}
