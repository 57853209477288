.toggle {
  position: relative;
  margin-bottom: 20px;
  padding: 0 0 0 20px;
  background: transparent;
  border: 0;
  color: inherit;
  font-size: 16px;
  text-decoration: underline;
}

.toggle:hover,
.toggle:focus {
  outline: 0;
  text-decoration: none;
  cursor: pointer;
}

.toggle::before {
  content: '';
  position: absolute;
  bottom: 0.25em;
  left: 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 2px solid transparent;
  border-left-color: inherit;
  border-bottom-color: inherit;
  transform: translateY(-1px) translateY(-50%) rotate(-45deg);
  transition: transform 0.2s ease-in-out;
}

.toggle[aria-expanded='true']::before {
  transform: translateY(3px) translateY(-50%) rotate(-225deg);
}

// Hide/Show labels
.toggle-label-hidden,
.toggle-label-visible {
  font-weight: normal;
}

[aria-expanded='false'] .toggle-label-visible {
  display: none;
}

[aria-expanded='true'] .toggle-label-hidden {
  display: none;
}

.toggle-right {
  padding: 0 1em 0 0;
}

.toggle-right::before {
  left: unset;
  right: 0;
}
