.container {
  max-width: 1240px;

  @include media-breakpoint-up(md) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

//<-- vertically and horizontally center the container's content -->
.container-centered {
  display: flex;
  justify-content: center;
  min-height: 70vh;
  align-items: center;
}
