body {
  // Scale down body text on smaller screens
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

h1,
.h1 {
  margin-bottom: 1em;
}

h2,
.h2 {
  margin-bottom: 1em;

  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}

h3,
.h3 {
  margin-bottom: 1em;
}

h4,
.h4, h5, .h5 {
  margin-bottom: 0.75em;
}

.display-1 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 32px;

  @include media-breakpoint-up(sm) {
    font-size: 44px;
  }

  @include media-breakpoint-up(md) {
    font-size: 56px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 70px;
  }
}

p {
  margin-bottom: $line-height-base + em;
}

.lead {
  margin-bottom: 2em;

  @include media-breakpoint-down(sm) {
    font-size: 1.3rem;
  }
}

.super-title {
  margin-bottom: 0.35em;
  color: $gray-600;
  font-size: 1rem;
  text-transform: uppercase;
}
