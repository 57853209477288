// Links
.nav-link {
  text-decoration: none;
}

// Tabs
.nav-tabs {
  font-size: 14px;
  border-bottom: 0;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  padding: 10px 20px;
  margin-right: -1px;
  border-color: $gray-400;
  border-bottom: 0;
  background: $gray-300;
  color: inherit;
  font-size: 13px;
  transition: background 0.15s ease-out;

  @include media-breakpoint-up(sm) {
    min-width: 140px;
    padding: 20px 30px;
    font-size: inherit;
    text-align: center;
  }
}

.nav-tabs .nav-link:not(.active):hover,
.nav-tabs .nav-link:not(.active):focus {
  border-color: $gray-400;
  background: $gray-400;
}

.nav-tabs .nav-link.active {
  font-weight: bold;
  border: 1px solid #fff;
  border-radius: 0;
}

.nav-tabs .nav-link-primary.active {
  border-color: $primary;
  background: $primary;
  color: #fff;
}

.nav-tabs svg {
  display: block;
  margin: 0 auto 5px;
  opacity: 0.3;
  transition: opacity 0.15s ease-out;

  @include media-breakpoint-down(sm) {
    transform: scale(0.7);
  }
}

.nav-tabs .active svg,
.nav-tabs .nav-link:hover svg,
.nav-tabs .nav-link:focus svg {
  opacity: 1;
}

// Vertical nav (as used on the report page)
.nav-vertical {
  @include list-unstyled;
  padding: 0;
  font-size: 1rem;
  text-align: center;

  @include media-breakpoint-up(lg) {
    background: #fff;
    text-align-last: left;
  }
}

.nav-vertical .nav-item {
  display: inline-block;
  margin: 3px;

  @include media-breakpoint-up(sm) {
    margin: 5px;
  }

  @include media-breakpoint-up(lg) {
    display: block;
    width: 100%;
    margin: 0;
  }
}

.nav-vertical a {
  padding: 5px;
  border: 1px solid $gray-300;
  border-radius: 4px;
  color: $primary;
  transition: background 0.15s ease-out;
  font-size: 11px;

  @include media-breakpoint-up(sm) {
    padding: 10px;
    font-size: 13px;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 40px;
    border: 0;
    border-top: 1px solid $gray-200;
    border-radius: 0;
    font-size: inherit;
  }
}

.nav-vertical a:not(.active):hover,
.nav-vertical a:not(.active):focus {
//  background: tint($primary, 92%);
}

.nav-vertical .active {
  position: relative;
  background: $primary;
  color: #fff;
  border-color: $primary;
  font-weight: bold;
}

.nav-vertical .active::before {
  @include media-breakpoint-up(lg) {
    content: '';
    position: absolute;
    right: 100%;
    top: 50%;
    border: 10px solid transparent;
    border-right-color: $primary;
    transform: translateY(-50%);
  }
}

// Sticky nav (as used on issue card page)
.nav-sticky {
  max-width: 360px;

  @include media-breakpoint-up(lg) {
    z-index: 100;
    position: sticky;
    top: 0;
    max-width: none;
    border-bottom: 1px solid $gray-200;
    border-top: 1px solid $gray-200;
    background: $gray-100;
  }
}

.nav-sticky-list {
  @include list-unstyled;
  display: block;
  margin: 0 -10px;
  font-size: 16px;

  @include media-breakpoint-down(md) {
    padding: 20px;
    border: 1px solid $primary;
    border-radius: 8px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
  }
}

.nav-sticky a {
  padding: 15px 10px;
}

.nav-sticky a:not(.active):hover,
.nav-sticky a:not(.active):focus {
  text-decoration: underline;
}

.nav-sticky .active {
  font-weight: bold;
}

// Table of contents
.nav-toc {
  @extend .p-2, .mb-2;
  background-color: $gray-300;
  display: inline-block;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.nav-vertical .nav-toc {
  @extend .mb-0;
  width: inherit;
  display: none;
  li {
    text-align: left;
  }

  a {
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    display: inline-block;
    padding-left: 60px;
    text-indent: -20px;
  }
}

.nav-vertical a.nav-link.active ~ .nav-toc {
  @include media-breakpoint-up(lg) {
    display: inline-block;
  }
}
