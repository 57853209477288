.pagination {
    list-style:none !important;
}

.page-item.active {
  .page-link {
    background-color: $gray-600;
    border-radius: .25em;
  }
}

.page-item {
    margin-left: 0.15em;
}

.page-link {
  line-height: 1em;
  border: 0;
  text-decoration: none;
}