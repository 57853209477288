@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/NotoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/NotoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
