.tab-bar {
  padding-top: 20px;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 35px;
  }
}

.tab-bar-actions {
  display: flex;
  margin: 0 -5px 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;
  }
}

.tab-bar-actions a {
  flex: 1 1 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;

  @include media-breakpoint-up(lg) {
    flex: 1 0 0;
  }
}
