.articleForm {
    @extend .timForm;

    label {
        @extend .w-100, .border-bottom;
    }

    input,
    select,
    textarea {
        @extend .form-control-plaintext, .bg-light;
    }

    .choices {
        background: #fff;
    }

    .choices__inner {
        border: none;
        background-color: unset;
    }
}

.article-container {
    @extend .container, .py-3;

    &>.row {
        flex-direction: row-reverse !important;
    }
}

.article-main {
    @extend .col-lg-9;

    header img {
        border-radius: 1rem;
    }

    section {
        @extend .mt-2, .mb-3, .w-100;
    }
}

.article-sidebar {
    @extend .col-lg-3, .list-group, .list-group-flush;

    &>section {
        @extend .bg-white, .list-group-item, .border-0;

        padding-bottom: 1.25rem;

        &:first-of-type {
            @extend .pt-2;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }

        &:last-of-type {
            @extend .pb-3;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

        h2 {
            @extend .text-primary;
        }

        h3 {
            @extend .h4, .text-primary, .border-top;
            margin-top: 1.25rem;
            padding-top: 1.25rem;
        }

        ul {
          @extend .list-unstyled, .mb-0;
        }
    }
}

.article-controls {
    @extend .list-group, .list-group-flush;
}

.article-tag {
    @extend .badge, .badge-pill, .mr-1, .text-wrap, .bg-info, .mb-1;

//     margin-bottom: 0.25rem;
}

.article-tag__label {
    @extend .d-inline-block, .text-break, .text-left;
    padding: 5px;
    font-weight: normal;

    &::first-letter {
      text-transform: uppercase;
      }
}

.article-links {
}

.article-link {
    @extend .row, .text-decoration-none, .align-items-baseline;

    &>span:first-of-type {
        @extend .col-2, .text-danger;
        font-weight: lighter;
    }
    &>span {
        @extend .col-auto, .text-body;
    }
}

.article-eyebrow {
    @extend .small;
    display: flex;
    justify-content: space-between;
    color: $primary;
    font-style: italic;
}


.article-type {
    @extend .mx-auto, .btn, .btn-sm, .text-white;
    position: relative;
    display: block;
    border: 2px solid white !important;
    margin-top: -2.2rem;
    margin-bottom: 2rem;
    &.library {
        background-color: $primary;
    }
    &.solution {
        background-color: $danger;
    }
    &.challenge {
        background-color: #878787;
    }
}


.community-filters {
    @extend .list-group, .list-group-flush;

    li:first-of-type {
      padding-top: 0px;
      }

    li {
        @extend .list-group-item, .border-0, .bg-transparent;
    }

    input:not(.choices__input),
    select {
        @extend .form-control;
    }

    //     .choices {
    //         background-color: #fff;
    //     }

    .choices__inner,
    .choices__input {
        background-color: white;
    }
}

.community-additem {
    @extend .d-flex, .flex-row-reverse, .py-2;

}

.community-list {
    @extend .search-container;
}

.community-listitem {
    @extend .searchresult, .pb-1;

}

.community-listitem__link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit !important;
    width: 100%;
}

.community-listitem__image {

    height: min-content;

    img {
        @extend .searchresult__image;
        height: 100%;
        max-height: 10rem;
        width: 100%;
        object-fit: cover;
        padding: 0 !important;
    }
}

.community-listitem__summary {
    @extend .searchresult__body, .px-3, .pb-3;


    p {
        @extend .small;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }

}

.community-listitem__eyebrow {
    @extend .mx-auto, .btn, .btn-sm, .px-2;
    position: relative;
    margin-top: -5em;
    border: white 2px solid !important;

    &.eyebrowtype__library {
        background-color: $primary;
    }

    &.eyebrowtype__solution {
        background-color: $danger;
    }

    &.eyebrowtype__challenge {
        background-color: #878787;
    }
}

.community-listitem__type {
    @extend .text-white, .my-auto;
}

.community-listitem__title {
    @extend .h4, .font-weight-bold, .text-primary;
}

.community-listitem__endnote {
    @extend .alert, .alert-warning;
}

.community-pagehelp {
    @extend .py-2;
}

.btn--more {
    @extend .btn-danger, .text-white;
}