.hero {
  padding: 20px 0;
  background: url('../images/hero-pattern.svg'),
    linear-gradient(to left, $tertiary, $secondary);
}

.hero-company-info {
  background: url('../images/hero-pattern.svg'),
    linear-gradient(to left, white 36%, $primary 66%);
}

#company-info {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }
}

.hero-company-info-details {
  flex: 3;
  margin-bottom: 10px;
  font-size: 15px;

  dl {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 0 30px;
    }
    margin-right: 30px;
  }

  @include media-breakpoint-up(md) {
    font-size: inherit;
  }
}

.hero-company-logo {
  margin-bottom: 0;

//   width: 160px;
  text-align: right;

  @include media-breakpoint-up(lg) {
    flex: 1;
    align-self: center;
  }

  img {
    max-width: 75%;
  }
}
