.dashboard-grid {
  @extend .row;

  flex-direction: column-reverse;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.dashboard-column {
  @extend .col;

  flex-direction: column;
}

.dashboard-card {
  @extend .card, .mb-1, .shadow-none, .mx-auto;

  padding: 10px !important;
  width: 70% !important;

  color: $primary;

  h2, h3 {
    @extend .card-title, .mb-2;

  }

  ul {
    @extend .card-text, .list-unstyled;
  }
}