.cms-content {

  h1{
    color: $primary;
    margin-top: 1em;
  }

  h2{
    color: $primary;
  }

  h3{
    color: $primary;
  }

  table{
    @extend .table, .table-striped;
  }

  .helpBoxContainer{
    margin-bottom: 1em;
  }

  a:link{
    text-decoration: none;
  }

  a:hover{
    text-decoration: underline;
  }
}

