/* Topic navigation
-----------------------------------------------------------------------------*/
.topicNav {
  filter: hue-rotate(-145deg) saturate(135%) brightness(102%);
  display: flex;
  flex-wrap: wrap;

  ul {
    list-style: none;
    list-style-image: none;
    padding: 0 0 5px;


    li {
      float: left;
      margin-right: 10px;
      width: 72px;
      text-align: center;
    }

    li.last {
      margin-right: 0;
    }

    li a:hover {
      border-bottom: 3px solid #8e191c;
      color: #4c403d;
    }

    li a {
      text-decoration: none;
      color: #8e191c;
      display: block;
    }

    li.selected a {
      border-bottom: 3px solid #8e191c;
    }

    li.selected a .topicText {
      display: block;
    }

    li.disabled .topicImage {
      opacity: 0.4;
      filter: alpha(opacity=40);
      background-color: #FFFFFF;
      /* For IE 6-7-8 to prevent ugly border*/
    }

    li a .topicText {
      display: none;
      padding-bottom: 2px;
      font-size: 75%;
      line-height: 1.2em;
    }

    li a:hover .topicText {
      display: block;
    }
  }
}

/* Topicimages */

.topicImageH50px.imgClipper .topicImage {
  display: inline-block;
  width: 45px;
  height: 50px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
  background: none;
}

.topicImageH50px.imgClipper img {
  margin-top: -52px;
}

.topicImageH50px .topicImage {
  background: transparent url("../images/topicIcons_H50px_sprite.png") no-repeat scroll left bottom;
  width: 45px;
  height: 50px;
}

.topicImageH50px .topicImage {
  margin-left: auto;
  margin-right: auto;
}


.disabled .roll-up,
.topicImageH50px a:hover .roll-up {
  background-position: left top;
}

.topicImageH50px.imgClipper .topicImage.roll-up img {
  margin-left: 0;
}

.topicImageH50px .governance {
  background-position: -45px bottom;
}

.disabled .governance,
.topicImageH50px a:hover .governance {
  background-position: -45px top;
}

.topicImageH50px.imgClipper .topicImage.governance img {
  margin-left: -45px;
}

.topicImageH50px .humanRights {
  background-position: -90px bottom;
}

.disabled .humanRights,
.topicImageH50px a:hover .humanRights {
  background-position: -90px top;
}

.topicImageH50px.imgClipper .topicImage.humanRights img {
  margin-left: -90px;
}

.topicImageH50px .labourPractices {
  background-position: -135px bottom;
}

.disabled .labourPractices,
.topicImageH50px a:hover .labourPractices {
  background-position: -135px top;
}

.topicImageH50px.imgClipper .topicImage.labourPractices img {
  margin-left: -135px;
}

.topicImageH50px .environment {
  background-position: -180px bottom;
}

.disabled .environment,
.topicImageH50px a:hover .environment {
  background-position: -180px top;
}

.topicImageH50px.imgClipper .topicImage.environment img {
  margin-left: -180px;
}

.topicImageH50px .fairOperatingPractices {
  background-position: -225px bottom;
}

.disabled .fairOperatingPractices,
.topicImageH50px a:hover .fairOperatingPractices {
  background-position: -225px top;
}

.topicImageH50px.imgClipper .topicImage.fairOperatingPractices img {
  margin-left: -225px;
}

.topicImageH50px .community {
  background-position: -270px bottom;
}

.disabled .community,
.topicImageH50px a:hover .community {
  background-position: -270px top;
}

.topicImageH50px.imgClipper .topicImage.community img {
  margin-left: -270px;
}

.topicImageH50px .consumerIssues {
  background-position: -315px bottom;
}

.disabled .consumerIssues,
.topicImageH50px a:hover .consumerIssues {
  background-position: -315px top;
}

.topicImageH50px.imgClipper .topicImage.consumerIssues img {
  margin-left: -315px;
}

h2.topicImageH50px {
  line-height: 50px;
  margin: 0;
}
