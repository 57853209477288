.registration-container {
  @extend .container-fluid, .p-0;
  height: 100vh;
}

.registration-wrapper {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.registration-header {

  position: relative;
  min-height: 15em;
  @include media-breakpoint-up(lg) {
    flex-basis: 33.333333%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 33.333333%;
  }
}

.registration-header-background {
  min-height: inherit;
  position: absolute;
  width: 100%;
  z-index: -1;  
  @include media-breakpoint-up(lg) {
    min-height: 100vh;
    height: 100vh;
  }
}

.registration-header-foreground {
  min-height: inherit;
  padding: 30px;
  @include media-breakpoint-up(lg) {
    padding: 80px;
  }
  position: absolute;
  color: white;
  h1 {
    text-align: center;
  }
  .payoff {
    position: relative;
    top: 45%;
  }
}

.registration-main {
  @include media-breakpoint-up(lg) {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 66.666666667%;
    .feedbackPanel {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}


.step {
  @extend .card;

  padding: 30px;
  @include media-breakpoint-up(lg) {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  margin-bottom: 30px;
}

.step-header {
  @extend .card-title;
  //   background-color: $gray-400;
  color: $primary;
  border-bottom: 2px solid $primary;

  margin-bottom: 0;
  h3 {
    margin-bottom: 0;
  }
}

.step-expand {
  text-decoration: none;
  float: right;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  &::after {
    display: inline-block;
    transition: transform linear 0.4s;
    content: "\f13a";
  }

  &[aria-expanded="true"] {
    &::after {
      transform: rotate(-180deg);
    }
  }
}

.step-body {
  @extend .card-body;
}
