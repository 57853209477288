.matrix {
  @extend .keep-together;

  font-size: 12px;
  position: relative;
  margin-bottom: 40px;

  @media print {
    break-inside: avoid;
    margin-left: 5em;
  }

  .legend {
    @extend .p-2, .mt-2;
    font-weight: bold;

    ul {
      display: flex;
      justify-content: space-evenly;
    }

    li {
      display: inline-block;
      &:before {
//         margin-left: 1.5em;
      }
    }
  }

  .toprow {
    .col {
      padding-top: 10px !important;
    }
  }

  .col {
    border-left: 1px solid black;
    padding-bottom:10px;

    ul {
      padding: 0 !important;
    }
  }

  .col.noline {
    border-left: 0;
    ul {
      padding-left: 1em !important;
    }
  }

  .col.rowlabel {
    border-left: 0;
    text-align: right;
    font-weight: bold;
  }

  .row {
    padding:0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    padding-left: 0em;
    display:flex;
  }

  li:before {
    content: "\f111"; /* FontAwesome Unicode */
    font-family: 'Font Awesome\ 6 Free';
    display: inline-block;
    margin-left: -2.6em;
    width: 1.2em;
    background-color: transparent;
    font-weight: 600;
    font-size: 12px;
  }

  li.z-0:before {
    @extend .legend-supplychain;
  }

  li.z-1:before {
    @extend .legend-company;
  }

  li.z-2:before {
    @extend .legend-productsandservices;
  }

  .x-title {
    text-align: center !important;
    font-size: larger;
    font-weight: 900;
  }

  .y-title {
    position: absolute;
    left: -3em;
    top: 50%;
    font-size: larger;
    font-weight: 900;
    transform:  rotate(-90deg) translateX(-25%);
    transform-origin: left;
  }

  .label-vertical {
    transform: rotate(-90deg) translateY(100%);
    font-weight: 700;
  }

  .row.chartfooter {

    .col {
      padding: 0;
      border: 0;
      font-weight: bold;
    }

    img {
      position: relative;
      left: -29px;
    }

  }

  .q-lowerleft {
    background-color: #FFF;
  }

  .q-upperleft {
    background-color: #E9E8E8;
  }

  .q-upperright {
    background-color: #EEE3E0;
  }

  .q-lowerright {
    background-color: #E9E8E8;
  }

}

.legend-supplychain {
  color: $dark;
}

.legend-company {
  color: $primary;
}

.legend-productsandservices {
  color: $secondary;
}

.matrix-lined .matrix__canvas {
  @media print {
    >div:first-of-type {
      .col {
        border-top: solid $border-width $border-color !important;
      }
    }

    div>div:first-of-type {
      border-left: solid $border-width $border-color !important;
    }

    div>div:last-of-type {
      border-right: solid $border-width $border-color !important;
    }

    >div:last-of-type {
      .col {
        border-bottom: solid $border-width $border-color !important;
      }
    }
  }
}
