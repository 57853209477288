.multiselect {
    display: flex;

    h4 {
        @extend .text-center;
        background-color: theme-color-level(secondary, -8);
    }

    .controls {
        @extend .mt-1;
        display: flex;
        flex-direction: column;

        button {
            @extend .btn, .btn-sm, .btn-secondary;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 0.2rem;
        }
    }

    .picklist select {
        width: 230px;
        max-width: 230px;

        option {
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    }
}
