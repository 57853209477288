.scoring-category {
  position: relative;
  padding-top: 60px;

  @include media-breakpoint-up(lg) {
    padding-top: 100px;
  }

  &:not(:first-of-type) {
    page-break-before: always;
  }
}

.scoring-category h2 {
  text-align: center;

  page-break-inside: avoid;
  page-break-after: avoid;

  @include media-breakpoint-up(xs) {
    display: flex;
    align-items: center;
    text-align: left;
  }
}

.scoring-category h3 {
  margin-bottom: 2em;
  page-break-inside: avoid;
  page-break-after: avoid;
}

// Body
.scoring-category-body {
  padding: 30px 25px;
  margin-bottom: 0.5rem;

  @include media-breakpoint-down(sm) {
    margin-right: -25px;
    margin-left: -25px;
  }

  @include media-breakpoint-up(sm) {
    padding: 60px;
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 80px 160px;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.scoring-category-body > :last-child {
  margin-bottom: 0;
}

.scoring-category-graphic {
  position: relative;
  top: -0.75em;

  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;

  page-break-inside: avoid;

  svg.score {
    height: 2.5em;
    width: 2.5em;

    .marker, .ring {
      stroke: $primary;
    }

    .ring {
      stroke-width: 2px;
    }
  }
}


.scoring-category-icon {
  display: block;
  width: 40px;
  margin: 0 auto 10px;
  page-break-inside: avoid;

  @include media-breakpoint-up(xs) {
    display: inline-block;
    width: 60px;
    margin: 0 20px 0 0;
  }
}

// Footer
.scoring-category-footer {
  @extend .keep-together;
  page-break-before: avoid;

  padding: 20px;

  @include media-breakpoint-up(lg) {
    display: flex;
    padding: 40px;
    transform: translate(20px, -70px);
  }

  @include media-breakpoint-up(xl) {
    transform: translate(60px, -70px);
    padding: 50px 60px;
  }

  @media print {
    display: flex;
    padding: 50px 60px;
    transform: translate(60px, -70px);
    border: solid 1px $gray-400;
    border-radius: 8pxFIR633;
  }
}

.scoring-category-footer * {
  margin: 0;
}

// Next steps
.scoring-category-footer > :first-child {
  flex: 0 0 auto;
  line-height: $line-height-base;
  margin-right: 30px;
}

svg.score {
  width: 32px;
  height: 32px;
  background: transparent;
  border-radius: 50%;
}

svg.score circle {
  fill: none;
  stroke: $primary;
  stroke-width: 4px;
}

svg.score circle.marker {
  stroke-width: 31.8px;
}

svg.score circle.max {
  stroke-width: 31.8px;
  stroke: #d7cdc9;
}

svg.score circle.ring {
  stroke-width: 4px;
}


