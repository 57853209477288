.firacomment {
  h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5em;
    margin-top: 1em;
  }
}

.readmore{
   @media screen {
    overflow:hidden;
    text-overflow: ellipsis;
    content: "";
    position:relative;
    &[data-readmore] {
      margin-bottom: 0;
      &[style*="height: 80px"]:before {
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        top:0;
        background:linear-gradient(transparent 1.5em, $light);
      }
    }
  }
  @media print {
    &[data-readmore] {
      height: auto !important;
    }
  }
}

.readmoretoggle {
  @media screen {
    margin-bottom: 1.5em;
    a{
      float: right;
      color: $secondary;
      position: relative;
      top: -1.2rem;
    }
  }

  @media print {
    display: none !important;
  }
}
