.btn {
  max-width: 240px;
  font-size: 11px;
  font-weight: bold;
  white-space: normal;
  text-decoration: none;

  @include media-breakpoint-up(sm) {
    font-size: 13px;
  }

  @include media-breakpoint-up(md) {
    font-size: 15px;
  }

  a {
    color: inherit;
  }
}

.btn-light {
  border: 1px solid $gray-200;
  background: #fff;
  color: $gray-600;
}

.btn-lg {
  padding: 1em;
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    font-size: 17px;
  }
}

a[role='button'] {
  text-decoration: none;
}

.btn-print {
  @extend .btn-light;
  &:after {
    @extend .ml-1;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f02f";

  }
}

.floating-controls {
  padding: 5px;
  width: fit-content;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 100;
}

// Tbe z-index of floating-controls implies we need to put this list *above* it.
.choices__list--dropdown {
  z-index: 110 !important;

  @media (min-width: 640px) {
    .choices__item--selectable {
      padding-right: unset !important;
    }
  }
}
