.user-nav {
  display: flex;
}

.user-nav-avatar {
  margin-right: 15px;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $secondary;
  color: #fff;
  text-align: center;
  line-height: 40px;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

#usermenu {
  position: absolute;
  z-index: 1000;
  left: 20px;
}

.user-nav-menu {
   width: max-content;

    border: 1px solid $secondary !important;

  ul {
    @extend .list-group, .list-group-flush;
    margin: 0 !important;
  }

  li {
    @extend .list-group-item;
    border-radius: 0 !important;
  }

}


.user-nav-username {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 170px;
}

.user-nav-body {
  flex: 1 0 auto;
}
