a.fa-star {
    text-decoration: none;
    color: $secondary !important;
}

.search-container {
    @extend .pb-3;
    font-size: 1rem;
    .displayname {
        font-weight: bold;
    }

    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr;

    @media (min-width: 640px) {
      grid-template-columns: 1fr 1fr;
    }

    a, a:hover {
        color: inherit;
    }

}

.searchresult {
  @extend .card, .p-0, .bg-light;
  max-height: 28rem;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.searchresult__header {
  @extend .bg-white, .pt-2, .px-2;
  display: flex;
  justify-content: space-between;
}

.searchresult__follow {
  @extend .fa-heart, .text-decoration-none, .text-secondary, .mr-2, .far;
  font-size: 1.5rem;

  &.selected {
    @extend .fas;
  }
}

.searchresult__status {
  display: inline-block;
}

.searchresult__compare {
  @extend .btn;

  width: 2.25rem;


  background-repeat: no-repeat;
  background-image: url("../icons/compare-notselected.svg");
  &.selected {
    background-image: url("../icons/compare-selected.svg");
  }
  &:focus {
    box-shadow: none !important;
  }
}

.searchresult__image {
  @extend .bg-white, .card-img-top, .p-2;
  height: 10rem;
  object-fit: contain;
}

.searchresult__title {
  @extend .card-title, .mb-1, .text-primary, .h3, .mt-2;
}

.searchresult__body {
  @extend .card-body, .text-center, .pb-0, .mx-3;
  flex-grow: 1;
  flex-shrink: 1;
  p {
    @extend .card-text, .mb-1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}

.searchresult__footer {
  @extend .py-2;
  margin-top: auto;
  align-self: center;
  font-weight: bold;
}


mark {
  padding: 0;
}

.noresults {
  @extend .alert, .alert-warning, .mt-1;
}

.comparison-container {
  @extend .d-flex, .flex-column, .align-items-end;
}

.comparison-panel {
  padding: 10px;
  background-color: $light;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ul {
    margin-bottom: 10px;
  }
}

.comparison-button {
  @extend .btn, .btn-primary;
}
.comparison-toggle {
  @extend .btn, .btn-secondary, .pl-1, .text-nowrap;

  &:before {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f078";
  }
  &[aria-expanded='false']:before {
    content: "\f077";
  }
}


#compare-veil {
  @extend #veil;
}

/* Autocomplete div */
div.wicket-aa-container {
  background-color: white;
  border: 1px solid $primary;
  padding: 2px;
  margin: 1px 0 0;
  text-align: left;
}

div.wicket-aa ul {
  list-style: none;
  padding: 2px;
  margin: 0;
}

div.wicket-aa ul li.selected {
  background-color: $mark-bg;
  padding: 2px;
  margin: 0;
}